import React, { useState, useRef } from "react";

export const ListItems = (props) => {
  let id = props.id;
  let defaultItem2 = "Girl";
  const [selectedItem, setSelectedItem] = useState(1);
  const [selectedItem2, setselectedItem2] = useState(defaultItem2);
  const dir = id === "Header" ? "img/TryOn/TryOn" : "img/" + id + "/" + id;
  const nItems = id === "Hair" ? 7 : id === "Combos" ? 5 : 3;
  let items2List = ["Girl", "Man"];
  if (id === "Hair") {
    items2List = ["Girl", "Do"];
  }
  if (id === "Combos") {
    items2List = ["Girl"];
  }

  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const changeItem = (increment) => {
    let newItem = 1;
    if (selectedItem === 1 && increment === -1) {
      newItem = nItems;
    } else if (selectedItem === nItems && increment === 1) {
      newItem = 1;
    } else {
      newItem = selectedItem + increment;
    }
    setSelectedItem(newItem);
  };

  const changePerson = (person) => {
    setselectedItem2(person);
  };

  const handleTouchStart = (e) => {
    touchStartX.current = e.changedTouches[0].screenX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.changedTouches[0].screenX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current - touchEndX.current > 50) {
      // Swipe left
      changeItem(1);
    }

    if (touchStartX.current - touchEndX.current < -50) {
      // Swipe right
      changeItem(-1);
    }
  };

  return (
    <div
      className="col-xs-12 col-md-6"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 30,
      }}
    >
      <div
        style={{
          minWidth: "304px",
          backgroundColor: "silver",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          borderRadius: "45px",
          display: "flex",
          paddingTop: "12px",
          paddingBottom: "12px",
          boxShadow: "11px 11px 14px rgba(0,0,0,.08)",
          border: "1px solid #BAB8B6",
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <button
          className="animatedButton"
          style={{
            position: "absolute",
            left: props.device.width > 400 ? 0 : -20,
            zIndex: 100,
          }}
          onClick={() => changeItem(-1)}
        >
          <i className={" fa fa-chevron-left"}></i>
        </button>
        <div
          style={{
            width: "280px",
            backgroundColor: "silver",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            borderRadius: "35px",
            display: "flex",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "50%",
              transform: "translateX(-50%)",
              width: "30%",
              height: "13px",
              backgroundColor: "silver",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              boxShadow: "3px 1px 14px rgba(0,0,0,0.2)",
            }}
          />
          <img
            src={dir + selectedItem2 + selectedItem + ".jpg"}
            style={{ width: "280px" }}
            alt=""
            onDragStart={(e) => e.preventDefault()}
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
        <button
          className="animatedButton"
          style={{
            position: "absolute",
            right: props.device.width > 400 ? 0 : -20,
            zIndex: 100,
          }}
          onClick={() => changeItem(1)}
        >
          <i className={" fa fa-chevron-right"}></i>
        </button>
      </div>

      <div
        style={{
          flexDirection: "row",
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          width: "300px",
          height: "70px",
          bottom: "-70px",
          display: "flex",
        }}
      >
        {items2List.length > 1 &&
          items2List.map((item, index) => {
            return (
              <div
                className="animatedButton"
                key={item}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "70px",
                  height: "70px",
                  display: "flex",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
                onClick={() => changePerson(item)}
              >
                {id === "Hair" && index === 0 && (
                  <i className={"fa fa-tint"}></i>
                )}
                {id === "Hair" && index === 1 && (
                  <i className={" fa fa-scissors"}></i>
                )}
                {id !== "Hair" && (
                  <img
                    src={dir + "Thumb" + item + ".jpg"}
                    onDragStart={(e) => e.preventDefault()}
                    onContextMenu={(e) => e.preventDefault()}
                    style={{
                      width: item === selectedItem2 ? "60px" : "70px",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};
